import React from 'react';
import Layout from '../components/Layout/Layout';
import NavBar from '../components/NavBar/NavBar';
import Footer from '../components/Footer/Footer';
import Form from '../components/RequestDemo/Form';

export default function RequestDemo() {
  return (
    <Layout>
      <NavBar />
      <Form />
      <Footer />
    </Layout>
  );
}
